/**
 * BlurScrollEffect - A class to handle blur effects on scroll
 * This applies a blur effect to elements as they scroll in and out of view
 * with proper handling for both scroll directions
 */
export class BlurScrollEffect {
  constructor(element, options = {}) {
    this.element = element;
    this.options = {
      maxBlur: options.maxBlur || 10,
      scrollThreshold: options.scrollThreshold || 0.3,
      transitionSpeed: options.transitionSpeed || 0.3,
      ...options
    };
    
    this.lastProgress = 0;
    this.init();
  }
  
  init() {
    // Create a GSAP ScrollTrigger for the element
    gsap.registerPlugin(ScrollTrigger);
    
    // Set up the scroll trigger to control blur amount
    ScrollTrigger.create({
      trigger: this.element,
      start: "top bottom",
      end: "bottom top",
      onUpdate: (self) => this.updateBlur(self),
      toggleActions: "play none none reverse",
      markers: false // Set to true for debugging
    });
    
    // Initialize with blur
    this.element.style.filter = `blur(${this.options.maxBlur}px)`;
    this.element.style.transition = `filter ${this.options.transitionSpeed}s ease-out`;
  }
  
  updateBlur(scrollTrigger) {
    // Get scroll progress and direction
    const progress = scrollTrigger.progress;
    
    // Detect scroll direction by comparing current progress to last progress
    const scrollingDown = progress > this.lastProgress;
    const scrollingUp = progress < this.lastProgress;
    this.lastProgress = progress;
    
    // Center zone where text is fully visible with no blur
    const centerZoneStart = this.options.scrollThreshold;
    const centerZoneEnd = 1 - this.options.scrollThreshold;
    
    let blurAmount;
    
    // If we're in the center zone, always keep text clear
    if (progress >= centerZoneStart && progress <= centerZoneEnd) {
      blurAmount = 0;
    }
    // When entering the viewport from the bottom (or scrolling back toward bottom)
    else if (progress < centerZoneStart) {
      // Calculate blur based on how far we are from the center zone
      const distanceFromCenter = centerZoneStart - progress;
      const percentageOfThreshold = distanceFromCenter / centerZoneStart;
      blurAmount = this.options.maxBlur * percentageOfThreshold;
      
      // Ensure clearer text when scrolling up from center toward bottom
      if (scrollingUp && progress > centerZoneStart - 0.1) {
        blurAmount = Math.min(blurAmount, 2);
      }
    }
    // When exiting the viewport to the top (or scrolling back from top)
    else if (progress > centerZoneEnd) {
      // Calculate blur based on how far we are from the center zone
      const distanceFromCenter = progress - centerZoneEnd;
      const percentageOfThreshold = distanceFromCenter / this.options.scrollThreshold;
      blurAmount = this.options.maxBlur * percentageOfThreshold;
      
      // Ensure clearer text when scrolling down from top toward center
      if (scrollingDown && progress < centerZoneEnd + 0.1) {
        blurAmount = Math.min(blurAmount, 2);
      }
    }
    
    // Apply the blur effect with clamping to avoid negative values
    const safeBlurAmount = Math.max(0, Math.min(this.options.maxBlur, blurAmount));
    this.element.style.filter = `blur(${safeBlurAmount}px)`;
  }
} 