
        import { BlurScrollEffect } from '/js/blurScrollEffect.js';

        document.addEventListener('DOMContentLoaded', () => {
            // Register GSAP plugin
            gsap.registerPlugin(ScrollTrigger);

            // Initialize the blur effect on target elements
            const elementsToBlur = document.querySelectorAll('.blur-on-scroll');
            elementsToBlur.forEach(el => {
                new BlurScrollEffect(el);
            });
        });
    